import React, {Suspense, useEffect} from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import {Loader} from '../components/Loader'
import {routes} from './../constants'
import {Router} from './Router'
import {Calculator as AECalculator} from './AutoEquity/Calculator'
import {Calculator as NAECalculator} from './NewAutoEquity/Calculator'
import {SimulationSummary as NAESimulationSummary} from './NewAutoEquity/SimulationSummary'
import {TaxesDetails as NAETaxesDetails} from './NewAutoEquity/TaxesDetails'
import {useFeatureTogglesActions} from './../store/featureToggles/useFeatureTogglesAction.hook'

const Pages = () => {
  const {fetchFeatureToggles} = useFeatureTogglesActions()

  useEffect(() => {
    fetchFeatureToggles()
  }, [fetchFeatureToggles])

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/" render={Router} />
          <Route exact path={routes.aeCalculator} component={AECalculator} />
          <Route exact path={routes.naeCalculator} component={NAECalculator} />
          <Route
            exact
            path={routes.naeSimulationSummary({
              applicationId: ':applicationId',
            })}
            component={NAESimulationSummary}
          />
          <Route
            exact
            path={routes.naeTaxesDetails}
            component={NAETaxesDetails}
          />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export {Pages}
