import {useEffect} from 'react'
import {useCustomerActions} from './../store/customer/useCustomerActions.hook'
import {useCustomerState} from './../store/customer/useCustomerState.hook'
import {log} from './../helpers/log'
import {fallbackToLocalTopUp} from './../helpers/webviewMessages'

const useSessionToken = () => {
  const {setCustomerInfo} = useCustomerActions()
  const {sessionToken, pollingAuthToken} = useCustomerState()

  useEffect(() => {
    if (!sessionToken && !pollingAuthToken) {
      log('startingAuthenticationTokenPolling')

      setCustomerInfo({pollingAuthToken: true})

      let count = 0

      const getSessionToken = setInterval(() => {
        if (count >= 10) {
          log('authenticationTokenNotInjected')

          setCustomerInfo({pollingAuthToken: false})
          clearInterval(getSessionToken)

          fallbackToLocalTopUp()
        } else {
          log('pollingAuthenticationToken')

          const localStorageSessionToken = window.localStorage.getItem(
            'sessionToken',
          )

          if (localStorageSessionToken) {
            log('authenticationTokenPollingSuccess')

            setCustomerInfo({
              pollingAuthToken: false,
              sessionToken: localStorageSessionToken,
            })

            clearInterval(getSessionToken)
          }
          count++
        }
      }, 1000)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    sessionToken,
  }
}

export {useSessionToken}
